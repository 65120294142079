@import "@angular/material/prebuilt-themes/indigo-pink.css";
@import "BasicStyle";
@import "variables";
@import "mainIcons";

// ----------- Ins-headersec -----------
/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
.mat-form-field-appearance-outline .mat-form-field-infix {
  padding: 0.3em 0 0.9em 0 !important;
}

.mat-primary .mat-pseudo-checkbox-checked,
.mat-primary .mat-pseudo-checkbox-indeterminate {
  background: #cbdb2a !important;
}

/* TODO(mdc-migration): The following rule targets internal classes of option that may no longer apply for the MDC version.*/
.mat-primary .mat-mdc-option.mat-selected:not(.mat-option-disabled) {
  color: #09614f !important;
}

#Ins-headersec {
  background: #fff;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.13);
  width: 100%;

  .navbar {
    flex-wrap: wrap;
    padding: 0;
  }

  .navbar-brand {
    max-width: 185px;
    flex: 0 0 185px;
    padding: 0;

    @media (max-width: 1200px) {
      max-width: 140px;
      flex: 0 0 140px;
    }
  }

  .tp-inRghtBg {
    display: flex;
    justify-content: space-between;
    align-items: center;

    p {
      color: #959595;
      font-family: "Avenir LT Std 55";
    }

    @media (max-width: 767px) {
      justify-content: flex-end;

      p {
        display: none;
      }
    }
  }

  &.sticky {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;

    .top1row {
      display: none;
    }
  }

  #MenuMainBg {
    .navbar-collapse {
      padding: 15px 20px;
      min-height: 70px;
    }

    .iconLogo {
      width: 45px;
      pointer-events: none;

      img {
        transition: all ease-in-out 0.3s;
        opacity: 0;
        position: relative;
        z-index: -2;
        height: 0;
      }

      @media (max-width: 991px) {
        display: none;
      }
    }

    .navbar-nav {
      > li {
        padding: 0 25px;
        position: relative;

        a {
          display: flex;
          align-items: center;
          font-family: "Avenir LT Std 55";
          color: #6c7174;
          font-size: 14px;

          i {
            transition: all 0.3s ease-in-out 0s !important;
            font-size: 18px;
            min-width: 34px;
            width: 34px;
            height: 34px;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            margin-right: 10px;
            background: transparent;
            border-radius: 8px;
          }

          &:hover {
            color: #0d6856 !important;

            i {
              background: rgba(13, 104, 86, 0.1);
            }
          }
        }

        &.active {
          > a {
            color: #0d6856 !important;

            i {
              background: rgba(13, 104, 86, 0.1);
            }
          }
        }

        .dropdown-menu {
          border-radius: 18px;
          box-shadow: 3px 4px 10px rgba(0, 0, 0, 0.1);
          padding: 15px;

          li.active > a {
            color: #0d6856 !important;
          }
        }

        @media (max-width: 1400px) {
          padding: 0 20px;
        }

        @media (max-width: 1200px) {
          padding: 0 10px;
        }

        @media (max-width: 991px) {
          padding: 6px 0;
        }
      }
    }

    &.active {
      .iconLogo {
        pointer-events: inherit;

        img {
          opacity: 1;
          z-index: 1;
          height: 39px;
        }

        @media (max-width: 991px) {
          margin-bottom: 15px;
          display: inline-block;
        }
      }
    }
  }

  @media (min-width: 992px) {
    .navbar-expand-lg .navbar-nav {
      justify-content: center;
    }
  }

  @media (max-width: 991px) {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9;

    &.sticky .top1row {
      display: flex;
    }
  }
}

#Ins-headersec.sticky + .content {
  .content-wrapper {
    margin-top: 170px;
  }
}

// ----------- Ins-headersec -----------

// --------- top section -----------
.top1row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 80px;
  border-bottom: 1px solid #e1e1e1;
  padding: 0 20px;

  .tp-inRght {
    display: inline-flex;
    align-items: center;

    li {
      margin: 0 15px;
      display: flex;
      align-items: center;

      a {
        color: #616161;
        font-size: 14px;
      }

      &:last-child {
        margin-right: 0;
      }

      &.prfl {
        ngx-avatars {
          margin: 0;

          .avatar-container {
            .avatar-content {
              width: 25px;
              height: 25px;
            }
          }
        }

        > a > span {
          width: 25px;
          height: 25px;
          border-radius: 50%;
          overflow: hidden;
          display: inline-flex;
          align-items: center;
          justify-content: center;
        }

        .HeaderSec {
          padding: 15px;
          border-bottom: 1px solid #d6d6d6;
          display: flex;
          flex-direction: column;
          align-items: center;

          span {
            width: 50px;
            height: 50px;
            border-radius: 50%;
            overflow: hidden;
          }

          h3 {
            margin: 12px 0 0;
            color: $default;
            font-size: 14px;
          }

          ngx-avatars {
            margin: 0;

            .avatar-container {
              .avatar-content {
                width: 50px;
                height: 50px;
              }
            }
          }
        }

        .tp-ftr {
          padding: 15px 0;
        }
      }

      &.tp-noti {
        position: relative;

        a > span {
          background: #dc5d21;
          width: 6px;
          height: 6px;
          border-radius: 50%;
          position: absolute;
          right: 0;
          top: -4px;
        }
      }
    }

    .notificationBg {
      min-width: 410px !important;
      padding: 10px 0;
      box-shadow: 0 1px 6px rgba(0, 0, 0, 0.16);

      li {
        margin: 0;
        border-bottom: 1px solid #e6ebef;

        &:last-child {
          border: none;
        }

        a {
          width: 100%;
          padding: 20px 40px;

          h4 {
            position: relative;
            color: #4f4f4f;
            font-size: 14px;
            margin: 0 0 8px;
            display: flex;
            justify-content: space-between;
            align-items: center;

            span {
              color: #5a5a5a;
              font-size: 11px;
              padding-left: 10px;
            }

            &:before {
              content: "";
              width: 8px;
              height: 8px;
              background: #c8c8c8;
              border-radius: 50%;
              position: absolute;
              left: -23px;
            }
          }

          p {
            color: #7d7d7d;
            font-size: 12px;
          }
        }

        &.new {
          a h4:before {
            background: $orange;
          }
        }
      }
    }

    @media (max-width: 767px) {
      display: none;
    }
  }
}
// --------- top section -----------

.copyright-inner {
  margin: 35px 0;
  color: #5b6870;
  font-size: 12px;

  @media (max-width: 767px) {
    margin: 20px 0;
  }
}

// --------------- login page ---------------
.LoginBg {
  min-height: 100%;
  margin: 0;

  .logIn-Rght {
    padding: 35px 15px;
    background: #fff;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    max-width: 425px;
    flex: 0 0 425px;
    flex-direction: column;
    position: relative;

    .lgnHead {
      position: absolute;
      top: 20px;
      left: 20px;
    }

    .loginCont {
      width: 285px;

      h1 {
        color: #0d6856;
        font-size: 24px;
        margin: 0;
        font-family: "Arimo", sans-serif;
        font-weight: 700;
      }

      p {
        font-size: 14px;
        line-height: 18px;
        margin-top: 8px;
      }

      .loginForm {
        margin-top: 75px;

        .form-group label {
          color: #959595;
          font-size: 16px;
          font-family: "Avenir LT Std 55";
        }

        .radiobtn {
          margin-top: 35px;

          label {
            color: #959595;
            font-size: 13px;
          }
        }

        .radiobtn input[type="radio"]:checked + label:after,
        .radiobtn input[type="checkbox"]:checked + label:after {
          background: #fff;
          border-color: #c2ccd8;
        }

        .recoveryCode-bg {
          max-width: 200px;
          margin-bottom: 35px;

          .col {
            padding-top: 3px;
            padding-bottom: 3px;
            color: #19967d;
          }
        }

        .common-btn {
          min-width: 110px;
        }

        @media (max-width: 767px) {
          margin-top: 40px;
        }
      }

      &.TwoFactorCont {
        width: 100%;
        padding: 0 15px;
        margin-top: 25px;

        .qrBg {
          width: 150px;
          height: 150px;
          margin: 35px 0;
          display: inline-flex;
          align-items: center;
          justify-content: center;
          border: 1px solid #bcbcbc;
        }

        .qrContent {
          .verifyCode {
            margin-bottom: 40px;
            margin: 0 -7px 40px -7px;

            [class*="col"] {
              padding: 0 7px;
            }

            .verifyBtn {
              max-width: 100px;
              flex: 0 0 100px;

              .btn {
                width: 100%;
              }
            }

            .form-control {
              &::-webkit-input-placeholder,
              &::-moz-placeholder,
              &:-ms-input-placeholder,
              &:-moz-placeholder,
              &:focus::-webkit-input-placeholder {
                color: #757575;
              }
            }
          }

          ul {
            margin: 0;
            padding: 0;

            h5 {
              color: #313131;
              font-family: "Avenir LT Std 65";
              margin: 0;
              font-size: 14px;
            }

            li {
              counter-increment: li;
              padding-left: 15px;
              margin-top: 15px;
              color: #5a5a5a;

              &:before {
                content: counter(li) ".";
                color: #959595;
                display: inline-block;
                width: 1.2em;
                margin-left: -1.2em;
              }

              span {
                background: #313131;
                padding: 3px 10px;
                color: #fff;
                cursor: pointer;
                border-radius: 20px;
                display: inline-block;
                word-break: break-all;
              }
            }
          }
        }
      }
    }

    .recoverCont,
    .credentials-Cont,
    .updated {
      .loginForm {
        margin-top: 20px;
      }
    }

    .OTP-cont {
      .otpBg {
        display: flex;
        margin: 60px 0 40px;

        .form-control {
          text-align: center;
          width: 42px;
          height: 42px;
          margin-right: 7px;
          padding: 8px 5px;

          &:last-child {
            margin: 0;
          }
        }

        @media (max-width: 767px) {
          margin-top: 40px;
        }
      }

      .resend {
        margin-top: 40px;

        a {
          color: #959595;
          font-size: 13px;

          &:hover {
            color: $secondary;
          }
        }
      }
    }
  }

  .signTextBg {
    padding: 80px 55px 0;
    display: flex;
    justify-content: center;

    .d-flex {
      width: 100%;
      max-width: 435px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: space-between;
    }

    .signText {
      display: flex;
      justify-content: center;
      width: 100%;
      margin: 30px 0;

      .textBox {
        width: 230px;
        position: relative;
        font-size: 23px;
        color: #959595;
        font-family: "Avenir LT Std 35";

        &:before,
        &:after {
          content: "";
          background: url(../images/quts.svg);
          width: 14px;
          height: 12px;
          position: absolute;
        }

        &:before {
          left: -20px;
          top: 3px;
        }

        &:after {
          background: url(../images/quts1.svg);
          bottom: 10px;
          right: 0;
        }
      }
    }
  }

  .lftImg {
    background: url(../images/loginBg.svg) no-repeat center top;
    background-size: contain;
    background-position: bottom;
    max-width: 405px;
    flex: 0 0 405px;
  }

  @media (max-width: 1400px) {
    .logIn-Rght {
      max-width: 400px;
      flex: 0 0 400px;
    }

    .lftImg {
      max-width: 380px;
      flex: 0 0 380px;
    }
  }

  @media (max-width: 1250px) {
    .lftImg {
      max-width: 300px;
      flex: 0 0 300px;
    }

    .signTextBg {
      padding: 80px 25px 0;
    }
  }

  @media (max-width: 1100px) {
    .lftImg {
      max-width: 200px;
      flex: 0 0 200px;
    }
  }

  @media (max-width: 991px) {
    .lftImg {
      display: none;
    }

    .logIn-Rght,
    .signTextBg {
      max-width: 50%;
      flex: 0 0 50%;
    }
  }

  @media (max-width: 767px) {
    .logIn-Rght,
    .signTextBg {
      max-width: 100%;
      flex: 0 0 100%;
    }

    .signTextBg {
      padding: 20px 10px 40px;
    }

    .logIn-Rght {
      padding: 40px 15px;
    }
  }
}
// --------------- login page ---------------

.pageHeading {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 28px;

  h1 {
    margin: 0;
    padding-left: 20px;
    color: $default;
    font-size: 29px;
    font-family: "Avenir LT Std 45 Book";
    position: relative;

    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      border-radius: 5px;
      width: 2px;
      background: #19967d;
    }
  }

  &.backarwHeading {
    h1 {
      padding: 0;

      a {
        margin-right: 22px;
      }

      &:before {
        display: none;
      }
    }
  }
  // --- filter-top ---
  .filter-top {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    .input-group {
      min-width: 242px;
      margin-right: 20px;
      width: auto;
      background: #fff;
      overflow: hidden;
      padding-left: 5px;
      box-shadow: 0 1px 2px rgba(0, 0, 0, 0.16);
      border-radius: 3px; /*height: 40px;padding: 5px 0;*/
      .input-group-prepend {
        width: 100%;
      }

      .form-control {
        background: #fff;
        height: 30px;
        color: #2d2d2d;
        padding: 8px 10px;
        text-align: center;
        border: none !important;
      }

      .btn {
        background: none;
        padding: 0 15px;
        font-size: 13px;
      }

      .arwBtn {
        border-left: 1px solid #e6eaee;
        min-width: 35px;
        max-width: 35px;
        display: inline-flex;

        .btn {
          padding: 0;
        }
      }

      .dropdown-toggle {
        width: 100%;
      }

      .dropdown .dropdown-menu {
        transform: none !important;
        top: 100% !important;
        margin-top: 6px !important;
        min-width: 140px !important;
      }
    }
    /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
    .mat-form-field-appearance-fill .mat-form-field-flex {
      background: #fff;
    }
    /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
    .mat-form-field-underline {
      display: none;
    }
    /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
    .mat-form-field-wrapper {
      padding-bottom: 0;
    }
  }
  // --- filter-top ---

  .RghtBlck {
    display: inline-flex;
    align-items: center;
    margin-bottom: 10px !important;

    .btn + .btn {
      margin-left: 20px;
    }

    .green-btn {
      background: #7ab648;
      color: #fff;
    }

    .input-group {
      margin-right: 20px;
    }
  }

  @media (max-width: 767px) {
    position: relative;
    z-index: 99;
  }

  @media (max-width: 576px) {
    flex-wrap: wrap;
    flex-direction: column;
    align-items: normal;

    h1 {
      margin-bottom: 15px;
      font-size: 25px;
    }

    .RghtBlck {
      justify-content: flex-end;
    }
  }
}

.input-group .bootstrap-select .btn {
  height: 30px;
  padding: 0 15px !important;
  box-shadow: none;
  border: none;
  border-left: 1px solid #e6eaee;
  font-size: 14px;
  color: #2d2d2d;
}

// ----- Dashboard -----------------------------------------------------
.tpGraph {
  .lftBox {
    display: flex;

    .box {
      width: 100%;

      .row {
        height: 100%;
      }

      .inBox {
        position: relative;
        padding: 25px 35px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        &:before,
        &:after {
          content: "";
          position: absolute;
          right: 0;
          width: 1px;
          height: 75px;
          background: #e1e1e1;
        }

        &:before {
          top: 0;
        }

        &:after {
          bottom: 0;
        }

        &:last-child {
          &:before,
          &:after {
            display: none;
          }
        }

        h2 {
          text-transform: uppercase;
          margin: 0;
          font-size: 12px;
          text-transform: uppercase;
          color: $primary;
          font-family: "Avenir LT Std 65";
        }

        .smGraphBg {
          height: 70px;
          display: flex;
          align-items: center;
          justify-content: center;
          margin: 10px 0;
        }

        .inBoxFtr {
          display: flex;
          justify-content: space-between;

          b {
            color: #9eafbf;
            font-size: 26px;
            line-height: 25px;
            font-family: "Avenir LT Std 85";
          }
        }

        @media (max-width: 1200px) {
          padding: 25px 15px;

          .inBoxFtr b {
            font-size: 22px;
          }
        }

        @media (max-width: 767px) {
          max-width: 50%;
          flex: 0 0 50%;
          margin: 10px 0;

          &:nth-child(even) {
            &:before,
            &:after {
              display: none;
            }
          }
        }

        @media (max-width: 576px) {
          max-width: 100%;
          flex: 0 0 100%;
          border-bottom: 1px solid #e1e1e1;

          &:last-child {
            border: none;
          }

          &:before,
          &:after {
            display: none;
          }
        }
      }

      @media (max-width: 991px) {
        padding: 15px;

        .inBox {
          padding: 25px 10px;
        }
      }
    }
  }

  .rghtBox {
    max-width: 240px;

    .greenBox {
      background: #cbdb2a;
      border-radius: 18px;
      padding: 35px 30px;
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      height: 100%;

      h5 {
        margin: 0;
        color: #0d6856;
        font-size: 12px;
        letter-spacing: 1px;
        line-height: 15px;
        font-family: "Avenir LT Std 85";
        text-transform: uppercase;
      }

      h1 {
        margin: 20px 0;
        color: green;
        font-size: 26px;
        text-align: right;
        font-family: "Avenir LT Std 85";
      }
    }

    @media (max-width: 1200px) {
      max-width: 200px;

      .greenBox {
        padding: 25px 20px;
      }
    }

    @media (max-width: 767px) {
      max-width: 100%;
      margin-top: 20px;
    }
  }
}

.upDwnArw {
  display: inline-flex;
  align-items: center;
  font-size: 12px;
  color: #6c7174;

  i {
    margin-left: 5px;
    font-size: 16px;
  }

  &.green {
    i {
      color: #25a748;
    }
  }
}

.myTaskBg {
  max-width: 450px;

  @media (max-width: 991px) {
    margin-top: 20px;
    max-width: 100%;
  }
}
// ----- Dashboard -----------------------------------------------------

// ----- Tasks -----------------------------------------------------

.programLabel {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 26px;
  padding: 3px 10px;
  border-radius: 5px;
  white-space: nowrap;
  font-size: 11px;
  font-family: "Avenir LT Std 65";

  i {
    margin-right: 10px;
    font-size: 16px;
  }

  &.green {
    background: rgba(13, 104, 86, 0.09);
    color: $green;

    i:before {
      color: $green;
    }
  }

  &.red {
    background: rgba(216, 17, 17, 0.09);
    color: $red;

    i:before {
      color: $red;
    }
  }

  &.rose {
    background: rgba(190, 18, 209, 0.09);
    color: $rose;

    i:before {
      color: $rose;
    }
  }

  &.green1 {
    background: rgba(51, 149, 3, 0.09);
    color: $green1;

    i:before {
      color: $green1;
    }
  }
}

.listViewHead {
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
  position: relative;
  // .SortBg{position: absolute;top: -43px;right: 205px;
  //   @media(max-width:1400px){right: 190px;}
  // }
}

// ----------Calendar-view----------
.CalendarViewHead {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  .d-inline-flex {
    align-items: center;
    position: absolute;
    top: -60px;
    right: 205px;

    .next-prev {
      display: inline-flex;

      span {
        width: 28px;
        height: 28px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        background: #fff;
        border-radius: 3px;
        box-shadow: 1px 1px 0 #dfe5e9;
        color: #6c6e6d;
        cursor: pointer;

        &:last-child {
          margin-left: 10px;
        }

        &.disabled {
          pointer-events: none;
          opacity: 0.5;
        }
      }
    }

    .dateTimeBg {
      input {
        width: 150px;
        border: none !important;
        background: transparent;
        text-align: center;
        color: #4f4f4f;
        font-size: 17px;
      }
    }

    .tdyLabel {
      font-size: 11px;
      color: $green;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      background: #fff;
      height: 30px;
      min-width: 46px;
      padding: 3px 10px;
      font-family: "Avenir LT Std 65";
      border-radius: 5px;
      margin-left: 10px;
    }
  }
}

.store-table {
  margin: 0 !important;
  flex-wrap: nowrap;
  overflow: auto;

  .dateBlock {
    min-width: 160px;
    padding: 0 !important;

    &:last-child,
    &:nth-last-child(2) {
      .customer-details {
        right: 0 !important;
        left: auto;
      }
    }

    @media (max-width: 1400px) {
      min-width: 140px;
    }
  }

  .t-block {
    border: 1px solid #e6eaee;
    height: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-right: none;
    border-bottom: none;
    color: #6c7174;
  }

  .t-block-half {
    margin: 1px 0;
    border: 1px solid #e6eaee;
    height: 34px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-right: none;
    border-bottom: none;
  }

  .timeleft {
    max-width: 140px;
    min-width: 140px;

    .t-block {
      border-left: none;

      &:first-child {
        border-top: none;
      }
    }
  }

  .timeFirstBlck {
    height: 60px;

    img {
      max-width: 13px;
    }
  }

  .weekblock {
    text-transform: uppercase;
    color: #6c7174;
    font-size: 12px;
    font-family: "Avenir LT Std 65";
    height: 60px;
    letter-spacing: 1px;
    border-top: none;
  }
}

.time-block-s {
  position: relative;

  .store-date {
    font-size: 13px;
    color: #404040;
    text-transform: uppercase;
    text-align: center;
    font-weight: 500;
    padding: 0 15px;
  }

  .store-time {
    font-size: 15px;
    color: #404040;
    text-transform: uppercase;
    text-align: center;
    font-weight: 400;
  }
}

.past-booking {
  position: absolute;
  border-radius: 7px;
  background: #eff2f5;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 9;
  padding: 20px 45px;

  .time-slot {
    color: #959a9f;
    font-size: 13px;
    text-align: left;
    margin: 0;
  }

  .book-name {
    color: #595f65;
    font-size: 13px;
    text-align: left;
    margin: 0;
    font-weight: 500;
  }

  &.active {
    background: #007ae8;

    .time-slot,
    .book-name {
      color: #fff;
    }
  }
}

.present-booking {
  min-height: 70px;
  position: absolute;
  border-radius: 7px;
  background: #eff2f5;
  border: 1px solid #e8ecf1;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 9;
  padding: 15px;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    width: 99%;
    height: 2px;
    background: #7ab648;
    border-radius: 10px;
    left: 0;
    right: 0;
    margin: auto;
  }

  &.active {
    background: #e6f8f4;

    .time-slot,
    .book-name {
      color: #0d6856;
    }
  }

  .time-slot {
    color: rgba(79, 79, 79, 0.63);
    font-size: 13px;
    text-transform: uppercase;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 6px;
  }

  .book-name {
    color: $default;
    font-size: 13px;
    text-align: left;
    margin: 0;
    font-weight: 500;
  }
}

.customer-details.rt .fa-close {
  color: #646565;
}

.customer-details.rt,
.p-absolute.rt {
  right: 0;
}

.customer-details,
.cstmModalBody {
  min-width: 400px;
  position: absolute;
  z-index: 91;
  background: #fff;
  padding: 15px 65px 30px;
  box-shadow: 0 0 60px rgba(0, 0, 0, 0.16);
  border-radius: 18px;
  left: 0;

  .cstmrClose {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 20px;
    position: relative;
    min-height: 15px;

    img {
      position: absolute;
      right: -35px;
      padding: 0 !important;
      cursor: pointer;
    }
  }

  .cstmrHeading {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .lft {
      position: relative;

      img {
        position: absolute;
        left: -30px;
      }

      .c-date {
        color: #404040;
        font-size: 15px;
        padding-right: 15px;

        &:last-child {
          padding: 0;
        }
      }
    }

    .rght {
      .c-edit {
        padding-left: 20px;

        &:first-child {
          padding: 0;
        }
      }
    }
  }

  .table {
    margin: 25px 0 0;

    tr {
      td {
        padding: 5px;

        &:first-child {
          padding-left: 0;
        }
      }
    }
  }
}
// ----------Calendar-view----------

// ----------kanbanViewBg----------
.kanbanViewMainBg {
  width: 100%;
  overflow: auto;
}

.kanbanViewBg {
  display: flex;
  max-width: 1245px;
  padding-bottom: 10px;

  .KanbanBox {
    min-width: 300px;
    position: relative;
    background: #fff;
    border-radius: 18px;
    margin-right: 15px;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.16);
    overflow: hidden;

    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      width: 92%;
      height: 2px;
      margin: auto;
    }

    &:last-child {
      margin-right: 0;
    }

    &.Todo {
      &:before {
        background: #d81111;
      }
      li{border: 1px solid #d81111;
        .d-flex{background: #d81111;
          .icon-prgrmIcon1:before{color: #8d0303;}
        }
      }
    }

    &.Progress {
      &:before {
        background: #7ab648;
      }
      li{border: 1px solid #7ab648;
        .d-flex{background: #7ab648;}
      }
    }

    &.Pending {
      &:before {
        background: #e49b0a;
      }
      li{border: 1px solid #e49b0a;
        .d-flex{background: #e49b0a;
          .icon-prgrmIcon1:before{color: #7c5404;}
        }
      }
    }

    &.Completed {
      &:before {
        background: #1182d8;
      }
      li{border: 1px solid #1182d8;
        .d-flex{background: #1182d8;
          .icon-prgrmIcon1:before{color: #023052;}
        }
      }
    }

    .header {
      height: 60px;
      display: flex;
      align-items: center;
      font-size: 17px;
      font-family: "Avenir LT Std 65";
      padding: 15px 20px;
      color: #19967d;
    }

    li {
      border-radius: 4px;
      margin-bottom: 6px;
      background: white;
      box-shadow: 0 4px 4px rgba(0,0,0,0.15);
      transition: all ease-in-out .3s;
      cursor: pointer;
      &:hover{box-shadow: 0 4px 4px rgba(0,0,0,0.35);}
      // border-top: 1px solid #e9e9e9;
      &.dragBox{padding: 0;}
      .d-flex{color: white;padding: 10px 15px;
        i{font-size: 16px;}
      }

      h6 {
        color: $default;
        font-size: 16px;
        font-family: "Avenir LT Std 65";
        margin-bottom: 6px;
      }
      .contArea{
        .flexbox{display: flex;justify-content: space-between;padding:8px 15px;border-bottom:1px solid #e1e1e1;
          font-size:13px;font-family: "Avenir LT Std 65";line-height:normal;
          b{text-transform: uppercase;}
          span{color: #0d6856;}
        }
        p {
          padding: 10px 15px;
          color: #4f4f4f;
          font-size: 13px;
          line-height: 18px;
          cursor: pointer;
        }
      }

      &:last-child{margin: 0;}
    }
  }
}

#KanbanModal {
  .cstmModalBody {
    padding: 15px 65px 30px;

    .cstmrHeading {
      padding: 0;
      border: none;
    }
  }
}

// ----------kanbanViewBg----------

// ----- Tasks -----------------------------------------------------

// ----- patients -----------------------------------------------------
ul.therapyView {
  padding: 0;

  li {
    display: flex;
    align-items: center;
    font-size: 14px;
    margin-bottom: 20px;

    &:last-child {
      margin: 0;
    }

    i {
      font-size: 9px;
      margin-right: 20px;
    }
  }
}

.PateintAddCont {
  .tab-pane {
    > .row {
      margin: 0 -20px;

      > .col {
        padding: 0 20px;
      }
    }
  }
}

.addNewTaskBox {
  /*max-width: 390px;flex:0 0 390px;*/
  max-width: 550px;
  flex: 0 0 550px;

  .box {
    padding: 0 0 15px;
    border: 1px solid #e6e6e6;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.16);
    border-radius: 6px;
    height: auto;

    .box-head {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 25px 20px;
      font-family: "Avenir LT Std 65";

      h3 {
        margin: 0;
        font-size: 17px;
        color: $secondary;
        margin: 0;
      }

      .common-btn {
        height: 30px;
        background: rgba(13, 104, 86, 0.09);
        color: $secondary;
        font-size: 12px;
        border-radius: 5px;
        min-width: auto;
        padding: 3px 10px;
      }
    }
  }

  @media (max-width: 1100px) {
    max-width: 100%;
    flex: 0 0 100%;
    margin-top: 30px;
  }
}

.tskListBox {
  padding: 10px 20px;
  border-bottom: 1px solid #dfdfdf;
  display: flex;
  align-items: center;
  justify-content: space-between;

  &:last-child {
    border: none;
  }

  .rghtSec {
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
    min-width: 130px;

    .checkbox label {
      &:before,
      &:after {
        top: 2px;
      }
    }
  }
}

.tree {
  > ul {
    padding: 0;
  }

  > ul > li:before,
  > ul > li:after {
    border: none;
  }

  li {
    margin: 0;
    position: relative;

    .collapsehead {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 !important;
      position: relative;
      z-index: 2;

      .collapseBranch {
        cursor: pointer;
        padding: 0;

        i {
          color: #e0e2e6;
          font-size: 15px;
          margin-right: 12px;
          position: relative;

          &:after {
            content: "";
            background: #313131;
            position: absolute;
            top: 3px;
            bottom: 3px;
            left: 0;
            width: 100%;
            z-index: 0;
            margin: auto;
          }

          &:before {
            position: relative;
            z-index: 1;
          }
        }
      }
    }

    &:before,
    &:after {
      content: "";
      left: -21px;
      position: absolute;
      right: auto;
    }

    &:before {
      border-left: 1px solid #e0e2e6;
      height: 100%;
      top: -21px;
      width: 1px;
    }

    &:after {
      border-top: 1px solid #e0e2e6;
      // height:20px;
      top: 18px;
      width: 15px;
    }

    &:first-child:before {
      height: 24px;
      top: -5px;
    }

    &.parent_li {
      ul {
        li {
          display: flex;
          justify-content: space-between;
          padding: 7px 0;

          .checkbox label:before,
          .checkbox label:after {
            top: 2px;
          }
        }
      }
    }
  }
}

.PatientlftBox {
  /*max-width: 70%;*/
  h3 {
    margin: 0 0 20px;
    font-size: 19px;
    color: $secondary;
    font-family: "Avenir LT Std 65";
    display: flex;
    align-items: center;
    justify-content: space-between;

    > a {
      color: #007ae8;
      font-size: 14px;
      margin-left: 25px;

      i {
        margin-right: 5px;
        font-size: 12px;
      }

      span {
        text-decoration: underline;
      }
    }

    .rghtSec {
      display: inline-flex;
      align-items: center;
    }
  }

  .subHead {
    font-size: 17px;
    font-family: "Avenir LT Std 65";
    margin-bottom: 20px;
  }
}

.BorderBox {
  padding: 22px 18px;
  border: 1px solid #ced4d8;
  border-radius: 6px;

  .table {
    tr {
      height: auto !important;

      &:hover {
        background: none;
      }
    }

    td {
      padding: 8px !important;

      .Vfied {
        display: flex;
        align-items: center;

        .radioBg {
          margin-left: 10px;
          height: auto;
          /* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
          .mat-checkbox-inner-container {
            top: -1px;
          }

          label {
            font-size: 13px;
            color: $primary;
          }
        }
      }

      &:first-child {
        padding-left: 0 !important;
      }
    }
  }
}

.addrsBg {
  margin-top: 35px; /*max-width:570px;*/
  &.addaddrsBg {
    max-width: 100%;
  }

  .addrsBoxBg {
    margin-bottom: 35px;

    &:last-child {
      margin: 0;
    }

    h4 {
      font-size: 17px;
      margin: 0 0 20px;
      font-family: "Avenir LT Std 65";
      display: flex;
      align-items: center;
      justify-content: space-between;

      a {
        color: #007ae8;
        font-size: 14px;
        margin-left: 25px;
        display: flex;
        align-items: center;

        i,
        img {
          margin-right: 5px;
          font-size: 12px;
        }
      }
    }

    .addrsBox {
      padding: 22px 18px;
      border: 1px solid #ced4d8;
      border-radius: 6px;

      .rghtBox {
        max-width: 180px;
        flex: 0 0 180px;
        display: flex;
        align-items: center;
      }

      h6 {
        margin: 0 0 10px;
        color: $default;
        font-family: "Avenir LT Std 85";
        font-size: 14px;

        span {
          font-family: "Avenir LT Std 55";
          color: #4f4f4f;
        }
      }

      p {
        color: #4f4f4f;
        line-height: 20px;
      }

      .form-group label {
        font-family: "Avenir LT Std 85";
      }

      .moreBg {
        width: 35px;
        position: inherit;
        margin-left: 10px;
      }
    }

    .addrsType {
      align-items: center;

      .radioBg {
        max-width: 145px;
        flex: 0 0 145px;
      }
    }
  }

  .addCnct {
    display: inline-flex;
    align-items: center;
    color: #007ae8;

    img {
      margin-right: 10px;
    }

    span {
      text-decoration: underline;
    }
  }
}

@media (max-width: 1200px) {
  .PateintAddCont {
    .navTab-Bg .nav-tabs .nav-link {
      padding: 12px 0;
    }
  }
}

.therapyDrug-table {
  margin-top: 50px;
}

// ----- patients -----------------------------------------------------

// ----- Manage User -----------------------------------------------------
.noLabel-check {
  flex-wrap: nowrap;
  align-items: center;

  .checkbox {
    width: auto;
    min-height: 16px;
    top: -2px;
  }

  .usrImg {
    width: 35px;
    height: 35px;
    border-radius: 50%;
    overflow: hidden;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin: 0 10px 0 4px;
  }

  label a {
    color: #212529;
  }
}

.roleTopGroup {
  .switchBg {
    max-width: 105px;
    flex: 0 0 105px;
  }
}
// ----- Manage User -----------------------------------------------------

// ----- Order Details -----------------------------------------------------
.OrderDetailsCont {
  .box {
    margin-bottom: 20px;
    height: auto;
    // &:last-child{margin-bottom: 0;}
    .boxHead {
      margin-bottom: 20px;

      h4 {
        color: #0d6856;
        font-size: 19px;
        font-family: "Avenir LT Std 65";
      }
    }

    .table {
      tr {
        height: auto !important;
      }

      tr:first-child td {
        padding-top: 0;
      }

      th {
        padding: 8px 5px !important;
      }

      td {
        padding: 8px 5px !important;
        vertical-align: top;
        color: #4f4f4f;

        &:first-child {
          padding-left: 0;
          font-family: "Avenir LT Std 65";
          color: #313131;
        }

        .usrTd {
          color: #4f4f4f;
          flex-wrap: wrap;
        }
      }
    }

    .programLabelBg {
      margin-bottom: 20px;

      &:last-child {
        margin: 0;
      }

      p {
        font-family: "Avenir LT Std 65";
        color: #6c7174;
        margin-bottom: 5px;
      }
    }

    &.trackingInfoBg {
      .table {
        tr td:first-child {
          width: 130px;
        }
      }
    }

    &.prdctDtlsBg {
      .table {
        tr:first-child td {
          padding-top: 0.75em;
        }

        td,
        td:first-child {
          color: #313131;
          font-family: "Avenir LT Std 55";
          padding: 0.75em;
        }

        tr.totalBg {
          td {
            font-size: 15px;
            font-family: "Avenir LT Std 65";
            text-align: right;
            border-top: 1px solid #e1e1e1 !important;
            border-bottom: 1px solid #e1e1e1 !important;

            .total {
              color: #0d6856;
              font-family: "Avenir LT Std 85";
            }
          }
        }

        th {
          padding-top: 0;

          &:first-child {
            padding-left: 0;
          }
        }
      }

      .order-ContDtls {
        max-width: 625px;
        margin: 20px 0 0;

        .table {
          tr {
            height: auto !important;

            &:hover {
              background: none;
            }
          }

          th {
            border: none;
            color: #313131;
            font-size: 14px !important;
            text-transform: capitalize;
            padding-bottom: 0 !important;
          }

          td {
            color: #4f4f4f;
          }
        }
      }
    }

    &.shppingDetailsBg {
      table {
        tr:hover {
          background: none;
        }
      }

      .shipping-row {
        padding: 20px 0;
        border-top: 1px solid #e1e1e1;

        h4 {
          color: #0d6856;
        }

        .table {
          max-width: 530px;

          td {
            vertical-align: baseline;

            &:first-child {
              width: 150px;
            }

            .bootstrap-select .btn {
              height: 42px;
            }
          }

          &:nth-child(1) {
            border: none;
            padding-top: 0;
          }
        }

        .addCnct {
          display: inline-flex;
          align-items: center;
          color: #007ae8;

          img {
            margin-right: 10px;
          }

          span {
            text-decoration: underline;
          }
        }
      }
    }

    .orderJourney {
      padding: 0;

      > li {
        align-items: center;
        margin-bottom: 20px;
        padding: 0;

        .orderRound {
          width: 27px;
          min-width: 27px;
          height: 27px;
          border-radius: 50%;
          background: #dfdfdf;
          border: 2px solid #dfdfdf;
          position: relative;
          display: inline-flex;
          justify-content: center;
          align-items: center;
          transition: all ease-in-out 0.3s;
          margin-right: 15px;

          img {
            transform: scale(1);
          }

          &:after {
            content: "";
            width: 1px;
            height: 14px;
            border-left: 1.5px dotted #b2b2b3;
            position: absolute;
            bottom: -19px;
          }
        }

        .lfttSec {
          color: #959595;
          font-family: "Avenir LT Std 65";
          display: flex;align-items: center;
          max-width: 210px;
          width: 100%;
        }

        .rghtSec {
          color: #959595;
          font-size: 12px;
          display: flex;align-items: center;

          span {
            // margin-left: 10px;
            padding: 0 5px;
            // white-space: nowrap;
            display: block;
            width: 25%;
            // &.date{white-space: nowrap;}
          }
        }

        &.completed {
          pointer-events: none;

          .orderRound {
            background: #7ab648;
            border-color: #7ab648;

            img {
              transform: scale(1);
            }

            &:after {
              border-left: 1.5px solid #7ab648;
            }
          }
        }

        &.active {
          .orderRound {
            background: #7ab648;
            border-color: #dfdfdf;
          }

          .lfttSec {
            color: #313131;
            cursor: pointer;
          }
        }

        &.declined {
          .orderRound {
            background: rgba(219, 30, 30, 0.16);
            border-color: #db1e1e;
          }

          .lfttSec {
            color: #313131;
          }
        }

        &.collapsed:last-of-type {
          .orderRound {
            &:after {
              display: none;
            }
          }
        }

        &:last-child {
          .orderRound {
            &:after {
              display: none;
            }
          }
        }

        &.rejected {
          pointer-events: none;

          .orderRound {
            background: #f9dcdc;
            border-color: transparent;
            border: 0;

            img {
              transform: scale(1);
            }

            &:after {
              border-left: 1.5px solid #e60f0f;
            }
          }
        }
      }
      // collapseBox
      .collapseBox {
        flex: 0 0 auto;
        width: 100%;
        padding-left: 13px;
        margin-top: -2px;
        margin-bottom: 10px;

        .card {
          border: none;
          border-left: 1.5px dotted #b2b2b3;
          border-radius: 0;
          padding-left: 25px;
          padding-top: 0;

          .confirmed {
            display: flex;
            align-items: center;
            color: #8e8e8e;
            font-size: 12px;

            span {
              display: inline-flex;
              align-items: center;
              margin-left: 10px;
              color: #313131;
              font-size: 14px;

              img {
                margin-right: 4px;
              }
            }
          }

          p,
          .d-flex {
            margin-top: 10px !important;
            color: #4f4f4f;
            display: flex;
            align-items: baseline;

            > label {
              max-width: 85px;
              flex: 0 0 85px;
            }

            > div {
              width: 100%;
            }

            > span {
              color: #007ae8;
              text-decoration: underline;
              margin-left: 5px;
            }

            &:first-child {
              margin-top: 0 !important;
            }
          }

          .common-btn {
            background: #19967d; /*max-width: 135px;*/
            margin-top: 10px;
          }
        }
      }
      // collapseBox
    }
  }
}

#selectAddress {
  .addrsBox {
    padding: 22px 18px;
    border: 1px solid #ced4d8;
    border-radius: 6px;

    .rghtBox {
      max-width: 85px;
      flex: 0 0 85px;
    }

    h6 {
      margin: 0 0 10px;
      color: $default;
      font-family: "Avenir LT Std 85";
      font-size: 14px;

      span {
        font-family: "Avenir LT Std 55";
        color: #4f4f4f;
      }
    }

    p {
      color: #4f4f4f;
      line-height: 20px;
    }

    & + .addrsBox {
      margin-top: 10px;
    }
  }
}

.orderModal {
  text-align: center;

  .orderBox {
    max-width: 370px;
    width: 100%;
    display: inline-block;
    padding: 25px 0;

    h5 {
      font-size: 19px;
      line-height: 25px;
      margin: 22px 0;
      font-family: "Avenir LT Std 65";
    }

    p {
      span {
        color: #007ae8;
        text-decoration: underline;
        margin-left: 5px;
      }

      + p {
        margin-top: 0;
      }

      &.red {
        color: #db1e1e;
      }

      &.grey {
        color: #9e9e9e;
      }
    }

    .checkBoxsBg {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 30px;

      .radioBg + .radioBg,
      .mat-mdc-checkbox + .mat-checkbox,
      .mat-radio-button + .mat-radio-button {
        margin-left: 20px;
      }
    }

    textarea,
    .mat-mdc-form-field {
      margin-top: 20px;

      textarea {
        height: 100px!important;
        resize: none;
        margin: 0;
      }
    }

    .btnsBg {
      margin-top: 35px;
      display: flex;
      align-items: center;
      justify-content: center;

      .btn + .btn {
        margin-left: 20px;
      }
    }

    .dtlsBlock {
      margin: 30px 0;

      .d-flex {
        align-items: center;

        .add {
          margin-left: 8px;

          img {
            width: 25px;
          }
        }
      }
    }

    .wrningMsg {
      display: inline-flex;
      padding: 10px 20px;
      border-radius: 8px;
      background: #d94f4f;
      color: #fff;
      font-size: 13px;
      margin-bottom: 15px;

      img {
        margin-right: 8px;
      }
    }
  }
}

#MakeAchange1 {
  .orderBox {
    max-width: 450px;
  }

  @media (min-width: 600px) {
    .modal-dialog {
      max-width: 555px;
    }
  }
}

.SuccessBox {
  text-align: center;
  height: 240px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  img {
    max-width: 45px;
  }

  p {
    font-size: 19px;
    color: #313131;
    font-family: "Avenir LT Std 65";
    margin-top: 30px;
  }
}

@media (min-width: 700px) {
  #OrderTasks {
    .modal-dialog {
      max-width: 640px;

      .orderBox {
        max-width: 490px;
      }
    }
  }
}

.orderCmntBox {
  margin-bottom: 20px;

  label {
    font-family: "Avenir LT Std 85";
    color: #313131;
    font-size: 14px;
    margin-bottom: 10px;
  }

  .form-control {
    border-right: none !important;
  }

  .btn {
    border: 1px solid #ced4d8;
    border-left: none;
  }
}

.dlvry-notesBg {
  ul {
    max-height: 555px;
    overflow: auto;
    padding: 0;
  }

  li {
    margin-bottom: 20px;

    .dlvry-Top {
      display: flex;
      align-items: center;

      .moreBg {
        width: 35px;
        position: inherit;
      }
    }

    .dlvry-Btm {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 10px;

      .noLabel-check {
        display: flex;
      }
    }

    .dlvry-notes {
      color: #4f4f4f;
      background: #e8ecf1;
      padding: 13px;
      border-radius: 6px;
      flex-grow: 1;
    }

    p {
      color: #8e8e8e;
      text-align: right;
      margin-top: 2px;
      font-size: 13px;
    }
    // &:last-child{margin-bottom: 0;}
  }

  .showMoreBtn {
    display: flex;
    justify-content: center;

    a.showMore {
      display: inline-flex;
      cursor: pointer;

      span {
        color: #6c7174;
        font-size: 14px;
        display: inline-flex;
        align-items: center;
        white-space: nowrap;

        img {
          margin-left: 6px;
        }

        &.Up {
          display: none;
        }
      }

      &.showLess {
        span {
          &.Dwn {
            display: none;
          }

          &.Up {
            display: inline-flex;
          }
        }
      }
    }
  }
}

// ----- Order Details -----------------------------------------------------

//-------Search Box ---------------------------------------------------------
.search-box {
  font-size: 20px;
  width: calc(100% - 30px);
  border: none;

  &:focus {
    outline: none;
  }
}

.search-result-item {
  // display: table;
  // table-layout: fixed;
  //padding: 1.00em 0.25em 0.5em 1.00em;
  border-top: 1px solid #ced4d8;
  cursor: pointer;
  text-decoration: none !important;
  // > div {
  //   display: table-cell;
  // }
  .search-result-name {
    padding-left: 15px;
  }
}

.search-result-name {
  padding-top: 10px;
  padding-bottom: 10px;
  // padding-left: 2em;
  // color: #000;
  text-decoration: none !important;
}

.no-extras {
  border: 1 !important;
  box-shadow: none !important;
  background-color: white !important;
}

.loderBG {
  position: fixed;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 99;

  img {
    max-height: 150px;
  }
}

.btnLoader {
  font-size: 20px;
}

app-main-nav {
  width: 100%;
}

.top_2 {
  margin: -26px 0px 20px 0 !important;
}
.pl-0 {
  padding-left: 0 !important;
}

.border_right_0 {
  border-right: 0 !important;
  border-radius: 7px 0px 0 7px;
}
.left_radius_0 {
  border-radius: 0 !important;
  -webkit-border-radius: 0 !important;
  -moz-border-radius: 0 !important;
  -ms-border-radius: 0 !important;
  -o-border-radius: 0 !important;
}
.right_radius_7 {
  border-radius: 0 7px 7px 0;
  -webkit-border-radius: 0 7px 7px 0;
  -moz-border-radius: 0 7px 7px 0;
  -ms-border-radius: 0 7px 7px 0;
  -o-border-radius: 0 7px 7px 0;
}
.radius_7 {
  border-radius: 7px !important;
  -webkit-border-radius: 7px !important;
  -moz-border-radius: 7px !important;
  -ms-border-radius: 7px !important;
  -o-border-radius: 7px !important;
}
.custom_wrp_alignment {
  flex-wrap: wrap;
  h1 {
    margin-bottom: 1rem;
  }
}
.nowrap {
  flex-wrap: nowrap;
}
@media (max-width: 992px) {
  .mr_sml_20 {
    margin-right: 20px;
  }
}
.no_white_space {
  white-space: nowrap;
}
.nav_menu li.active a {
  color: #0d6856 !important;
}
.m-20 {
  margin: 0 20px;
}
.ml-20 {
  margin-left: 20px !important;
}
.align_warning {
  position: absolute;
}
.OrderDetailsCont .box .orderJourney > li.rejected .orderRound {
  background-image: url(../images/decline-ico.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
}
.OrderDetailsCont .box .orderJourney > li.rejected .orderRound img {
  display: none;
}
.table-responsive.tbl_align_ .mat-mdc-select.form-control {
  height: 36px !important;
}
/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
.table-responsive.tbl_align_
  .mat-form-field-type-mat-date-range-input
  .mat-form-field-infix {
  border-top: 0 !important;
}
/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
.table-responsive.tbl_align_
  .mat-form-field-type-mat-date-range-input
  .mat-form-field-infix
  .mat-date-range-input-container {
  align-items: inherit !important;
}
/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
.table-responsive.tbl_align_
  .mat-form-field-appearance-fill
  .mat-form-field-flex {
  padding: 1px 5px !important;
}
/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
.table-responsive.tbl_align_
  .mat-mdc-form-field:not(.mat-form-field-appearance-legacy)
  .mat-form-field-suffix
  .mat-mdc-icon-button {
  top: 10px !important;
  right: -8px !important;
}
.table-responsive.tbl_align_ .mat-date-range-input-separator {
  padding-top: 9px !important;
}
.w-170 {
  width: 170px;
}
h1.order_id {
  margin: 0 !important;
  font-size: 22px !important;
  margin-bottom: 10px;
  a {
    margin-right: 8px !important;
  }
}
.tbl-filtr.icon_set .mat-icon {
  position: absolute;
  z-index: 9;
  font-size: 15px;
  right: 20px;
  top: 20px;
}
/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
.tbl-filtr.align_tp .mat-form-field-outline-end {
  border: 0 !important;
}
/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
.tbl-filtr.align_tp .mat-form-field-outline-start {
  border: 0 !important;
}
/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
.tbl-filtr.align_tp .mat-form-field-infix {
  border-top: 0 !important;
  padding: 0.2em 0 0.5em 0 !important;
}
/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
.tbl-filtr.align_tp .mat-form-field-flex {
  padding: 0 !important;
}
.range_picker .mat-icon {
  position: absolute;
  z-index: 9;
  font-size: 15px;
  right: 0;
  top: 0;
  cursor: pointer;
}
.common-btn {
  @media (max-width: 575px) {
    font-size: 12px !important;
    padding: 5px 12px !important;
  }
}
.tble_height {
  max-height: 400px;
}
i.fa.fa-adjust {
  color: #7ab648;
  font-size: 45px;
}
.sub_head {
  color: #0d6856 !important;
  font-size: 19px !important;
  margin: 0 0 30px !important;
  font-family: "Avenir LT Std 65" !important;
}
.cdk-overlay-pane {
  max-width: 800px !important;
}
.custom_min_width {
  min-height: 246px;
}
.text-right {
  text-align: right !important;
}
.line_ {
  border-color: #000;
}
.custom_sec_ {
  .btn-outline-secondary {
    border: 1px solid #ced4d8 !important;
    background: #d6e4e3;
    color: #0d6856;
    &:hover {
      background-color: #ddd;
    }
  }
  label {
    margin-bottom: 5px;
    @media (max-width: 992px) {
      margin-top: 1.5rem;
    }
  }
  i.fa.fa-trash-o {
    font-size: 22px;
    padding-top: 24px;
  }
  .form-select {
    height: 42px;
  }
  .noLabel-check {
    display: flex;
    align-items: center;
  }
}

.align-item-center {
  align-items: center;
}
.cdk-global-overlay-wrapper {
  overflow: auto;
}
.user_info {
  p span {
    color: #000;
    font-weight: 600;
  }
  a.reset_ {
    color: #1499f5;
  }
}
.icon_prof {
  display: flex;
  align-items: center;
  @media (max-width: 991px) {
    margin-bottom: 20px;
  }
  > div {
    width: 60px;
    height: 60px;
    text-align: center;
    border-radius: 50%;
    border: 1px solid rgb(163, 217, 119);
    text-transform: uppercase;
    color: rgb(255, 255, 255);
    background-color: rgb(13, 104, 86);
    font: 11px / 35px Helvetica, Arial, sans-serif;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 800;
  }
}
.scrollbar_manage {
  height: 830px;
  overflow: auto;
  > div {
    min-height: 100%;
  }
}
.item_scroll {
  max-height: 400px;
  overflow-x: hidden;
  overflow-y: auto;
}
.ml_3 {
  margin-left: 1.3rem;
}
.access_denied {
  text-align: center;
  background-color: #fff;
  margin: 10rem auto;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40%;
  border-radius: 15px;
  box-shadow: 0px 3px 12px #0000001f;
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  -ms-border-radius: 15px;
  -o-border-radius: 15px;
  padding: 0 10px 45px 10px;
  @media (max-width: 992px) {
    width: 90%;
  }
  img {
    width: 70px;
    position: relative;
    margin-top: -33px;
    background-color: #fff;
    border-radius: 100%;
    padding: 14px;
  }
  h1 {
    font-size: 2.4rem !important;
    color: #0d6856;
    margin: 20px 0 16px !important;
  }
}
.copyright-inner.to_bottom {
  position: relative;
  bottom: 0;
  left: 28px;
  top: 15px;
  z-index: -1;
}
.ftr-custom {
  border-top: 1px solid #e1e1e1;
  .btn {
    background-color: transparent;
    font-weight: 600;
  }
}
.pe-7 {
  padding-right: 7rem !important;
}
a.linked-bg {
  background: #c9dfff;
  color: #000;
  padding: 1px 8px;
  display: inline-block;
  margin: 2px 0;
  border-radius: 12px;
}
.WithDrawnPopup {
  max-width: 540px !important;
}
.DeletePopup,
.UpdatePopup,
.smallPopup {
  max-width: 500px !important;
}
/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
.mat-form-field-appearance-outline .mat-form-field-outline-start,
.mat-form-field-appearance-outline .mat-form-field-outline-end {
  border-color: #ced4d8 !important;
}
.mat-mdc-dialog-container {
  overflow: inherit !important;
}
@media (min-width: 576px) {
  .largePopup {
    max-width: 500px !important;
  }
}
@media (min-width: 992px) {
  .largePopup {
    max-width: 800px !important;
  }
}
@media (min-width: 1200px) {
  .largePopup {
    max-width: 1140px !important;
  }
}
.statusNew {
  position: relative;
  display: inline-flex;
  align-items: baseline;
  white-space: nowrap;
  line-height: normal;
  i {
    width: 9px;
    min-width: 9px;
    height: 9px;
    border-radius: 50%;
    background: #7ab648;
    margin-right: 6px;
  }
}
.rxStatusDrop {
  position: relative;
  .btnLoader {
    position: absolute;
    width: 100%;
    text-align: center;
    height: 27px;
    i {
      position: relative;
      top: -3px;
      color: #0070bb;
    }
  }
  .mat-mdc-button,
  .mat-mdc-select {
    min-width: auto;
    padding: 0 10px;
    border: 1px solid #0070bb;
    line-height: 25px;
    background: white;
    font-weight: 500;
    border-radius: 4px;
    white-space: nowrap;
    height: auto;
  }
  .mat-mdc-select {
    height: 27px;
    /* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.*/
    .mat-select-value {
      color: #0070bb;
      font-weight: 500;
    }
    /* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.*/
    .mat-select-trigger .mat-select-arrow {
      color: #0070bb;
      position: relative;
      top: -2px;
    }
  }
  /* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version.*/
  .mat-focus-indicator .mat-button-wrapper {
    color: #0070bb;
  }
}

.wordBreak {
  white-space: normal;
  word-break: break-word;
  height: auto;
  min-height: 26px;
  align-items: flex-start;
  padding: 5px 10px;
}
smart-list-item .smart-input{width: 20px!important;height: 20px!important;margin-left: 0!important;}

.mat-checkbox-ripple .mat-ripple-element,
.mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: red !important;
}
.mdc-checkbox{padding: calc((var(--mdc-checkbox-state-layer-size) - 18px) / 2)!important;
  margin: calc((var(--mdc-checkbox-state-layer-size) - var(--mdc-checkbox-state-layer-size)) / 2)!important;}
  .mat-mdc-checkbox .mdc-checkbox .mdc-checkbox__native-control{
    top: calc((var(--mdc-checkbox-state-layer-size) - var(--mdc-checkbox-state-layer-size)) / 2)!important;
  right: calc((var(--mdc-checkbox-state-layer-size) - var(--mdc-checkbox-state-layer-size)) / 2)!important;
  left: calc((var(--mdc-checkbox-state-layer-size) - var(--mdc-checkbox-state-layer-size)) / 2)!important;
  width: var(--mdc-checkbox-state-layer-size)!important;
  height: var(--mdc-checkbox-state-layer-size)!important;}
  .mdc-checkbox .mdc-checkbox__background {
    top: calc((var(--mdc-checkbox-state-layer-size) - 40px) / 2)!important;
    left: calc((var(--mdc-checkbox-state-layer-size) - 28px) / 2)!important;
}
.mat-mdc-checkbox .mdc-checkbox__ripple{opacity: 0!important;}
.mdc-form-field>label{line-height: normal;padding-left: 8px!important;margin-bottom: 0;}
.mat-mdc-checkbox.mat-accent{
  --mdc-checkbox-selected-focus-icon-color: #7AB648!important;
  --mdc-checkbox-selected-hover-icon-color: #7AB648!important;
  --mdc-checkbox-selected-icon-color: #7AB648!important;
  --mdc-checkbox-selected-pressed-icon-color: #7AB648!important;
}
.mat-mdc-slide-toggle.mat-accent {
  --mdc-switch-selected-focus-state-layer-color: #0D6856 !important;
  --mdc-switch-selected-handle-color: #0D6856 !important;
  --mdc-switch-selected-hover-state-layer-color: #0D6856 !important;
  --mdc-switch-selected-pressed-state-layer-color: #0D6856 !important;
  --mdc-switch-selected-focus-handle-color: #0D6856 !important;
  --mdc-switch-selected-hover-handle-color: #0D6856 !important;
  --mdc-switch-selected-pressed-handle-color: #0D6856 !important;
  --mdc-switch-selected-focus-track-color: #c5ede4 !important;
  --mdc-switch-selected-hover-track-color: #c5ede4 !important;
  --mdc-switch-selected-pressed-track-color: #c5ede4 !important;
  --mdc-switch-selected-track-color: #c5ede4 !important;
}

.mdc-switch__ripple{display: none;}

.mat-mdc-radio-button.mat-accent{
  --mdc-radio-selected-focus-icon-color: #7AB648;
  --mdc-radio-selected-hover-icon-color: #7AB648;
  --mdc-radio-selected-icon-color: #7AB648;
  --mdc-radio-selected-pressed-icon-color: #7AB648;
  --mat-mdc-radio-checked-ripple-color: #7AB648;
}
.mat-mdc-radio-button .mdc-radio{padding: 0!important;}


.mat-mdc-text-field-wrapper{height: 39px!important;}
.textarea .mat-mdc-text-field-wrapper{height: auto!important;}
.tbl-filtr{
  .mat-mdc-text-field-wrapper.mdc-text-field--outlined .mat-mdc-form-field-infix{padding-top: 5px;padding-bottom: 5px;}
  .mat-mdc-text-field-wrapper{height: 36px!important;}
}

.mat-mdc-text-field-wrapper.mdc-text-field--outlined .mat-mdc-form-field-infix{    padding-top: 8px;
  padding-bottom: 8px;
  font-size: 14px;}
.mat-mdc-form-field-infix{min-height: auto;}
.mat-mdc-form-field-subscript-wrapper{display: none;}




.mdc-text-field--outlined:not(.mdc-text-field--disabled){
  .mdc-notched-outline__leading,.mdc-notched-outline__notch,.mdc-notched-outline__trailing{
    border-color:#CED4D8;color: #495057;
  }
}
.mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
.mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
.mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing{
  border-width: 1px;
  border-color:#CED4D8;
}
.mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__leading,
.mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__notch,
.mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__trailing{border-color:#CED4D8;color: #495057;}
.mat-mdc-icon-button .mat-icon{padding: 0!important;}

.mdc-text-field--filled:not(.mdc-text-field--disabled){background-color: transparent;}
.mat-mdc-icon-button .mat-mdc-button-persistent-ripple{display: none;}
.mat-mdc-icon-button.mat-mdc-button-base{padding: 0 12px;}
.mat-mdc-select-value{font-size: 14px;}
.mat-mdc-option .mdc-list-item__primary-text{font-size: 14px!important;}

.mat-mdc-text-field-wrapper:not(.mdc-text-field--outlined) .mat-mdc-form-field-infix{}
//.mat-mdc-text-field-wrapper:not(.mdc-text-field--outlined) .mat-mdc-form-field-infix{padding: 0!important;}
.mat-date-range-input-wrapper{font-size: 14px;
  //input{padding: 0!important;}
}
.mat-mdc-text-field-wrapper .mat-mdc-form-field-focus-overlay{display: none;}
.tableFilter .tbl-filtr .mat-date-range-input-wrapper {max-width: 70px;
  input{padding: 0!important;min-width: auto;font-size: 13px;}
}
.table-responsive.tbl_align_ .mat-date-range-input-separator{padding-top: 0!important;}
.tbl-filtr{
  .mat-mdc-text-field-wrapper{padding: 5px;display: flex;align-items: center;border: 1px solid #CED4D8;border-radius: 4px;}
  .mat-mdc-form-field-type-mat-select .mat-mdc-text-field-wrapper{border: none;padding-left:16px;padding-right:16px;}
}
.mdc-line-ripple,.mdc-line-ripple::before{display: none;}
.mat-mdc-icon-button.mat-mdc-button-base{display:inline-flex;align-items: center;height: auto;margin-bottom: 15px }
.searchBg{
  .mat-mdc-icon-button{color: #616161;
    .mat-icon{font-size: 18px;width: 18px;height: 18px;}
  }
}
.table {
  .action,.mdc-data-table__cell{overflow: inherit;}
}
.tbl-filtr.icon_set .mat-icon{width: 15px;height: 15px;right: 30px;}
.addrsBg .addrsBoxBg .addrsType .radioBg{max-width: 160px;flex: 0 0 160px;}
.tableFilter .tbl-filtr .mat-mdc-icon-button.close{right: 30px;top: 5px;}

.range_picker {
  .mat-mdc-text-field-wrapper{height: 48px!important;
    .mat-mdc-form-field-flex .mat-mdc-floating-label{top: 24px;}
    .mat-date-range-input{position: relative;top: 16px;}
    .mat-mdc-icon-button.mat-mdc-button-base{height: 48px;}
    .mat-icon{width: 15px;height: 15px;top:16px;right:-10px;}
  }
}
.ngx-mat-timepicker form .ngx-mat-timepicker-table .ngx-mat-timepicker-tbody tr td .mat-mdc-form-field .mdc-text-field--filled{height: auto!important;}
// html,body{height: 100vh!important;}
.smart-grid-header{height: 31px!important;}
.smart-data-view-header{padding: 0 3px!important;}
.smart-data-view-header-button{margin-top: 0!important;}
.smart-grid-pager smart-pager{margin-top: 0!important;}
.smart-grid-pager{height: 39px!important;display: flex!important;align-items: center!important;}
smart-grid-cell[template] .smart-label{padding-left: 7px!important;}
.mdc-form-field > label{font-family: "Avenir LT Std 55";}
.patinetCheck .mdc-form-field > label{padding-left: 0!important;font-family: "Avenir LT Std 55";}
.mat-mdc-dialog-container .mdc-dialog__surface{border-radius: 14px!important;}
.form-group .mat-mdc-form-field{border-radius: 4px!important;background: white;}
.selected-list .c-btn{position:relative !important;}
.selected-list .c-angle-down, .selected-list .c-angle-up{top:16px!important;}


.mat-datepicker-content .mat-calendar {
  height: auto !important;
}